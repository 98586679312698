// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drawing {
  top: 0;
  left: 0;
  min-width: 100%;
  max-width: 100%;
  height: calc(100% - 77px - 40px);
  margin-top: 40px;
  position: absolute;
  display: inline-block;
}
@media (max-width: 1199.98px) {
  .drawing {
    margin-top: 0px;
    height: calc(100% - 77px);
  }
}

.shade {
  position: absolute;
  background: transparent;
  z-index: 1;
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Canvas/DrawLayer.scss"],"names":[],"mappings":"AACA;EACE,MAAA;EACA,OAAA;EACA,eAAA;EACA,eAAA;EAEA,gCAAA;EACA,gBAAA;EACA,kBAAA;EACA,qBAAA;AADF;AAEE;EAVF;IAWI,eAAA;IACA,yBAAA;EACF;AACF;;AAGA;EACE,kBAAA;EACA,uBAAA;EACA,UAAA;EACA,oBAAA;AAAF","sourcesContent":["@import '../../variables';\n.drawing {\n  top: 0;\n  left: 0;\n  min-width: 100%;\n  max-width: 100%;\n  // Full height - footer height - header-height\n  height: calc(100% - 77px - 40px);\n  margin-top: 40px;\n  position: absolute;\n  display: inline-block;\n  @media (max-width: 1199.98px) {\n    margin-top: 0px;\n    height: calc(100% - 77px);\n  }\n}\n\n// shaders to darken the \"unprintable\" area\n.shade {\n  position: absolute;\n  background: transparent;\n  z-index: 1;\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
