// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom.dropdown-menu {
  width: 100%;
  padding: 0;
  height: 500%;
  overflow-y: scroll;
}

.custom > .dropdown > .dropdown-toggle {
  display: inline-flex;
  border-bottom: 1px solid lightgray;
  height: 70px;
}

.custom.dropdown-item {
  display: inline-flex;
  border-bottom: 1px solid lightgray;
  height: 70px;
}

.choose-quantity {
  margin: auto 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Forms/Quantity/ChooseQuantity.scss"],"names":[],"mappings":"AAMA;EACE,WAAA;EACA,UAAA;EACA,YAAA;EACA,kBAAA;AALF;;AAQA;EACE,oBAAA;EACA,kCAAA;EACA,YAAA;AALF;;AAQA;EACE,oBAAA;EACA,kCAAA;EACA,YAAA;AALF;;AAQA;EACE,cAAA;AALF","sourcesContent":["// Choose Quantity CSS\n// --------------------------------------------------\n\n// Dropdown Custom Style\n// -------------------------\n\n.custom.dropdown-menu {\n  width: 100%;\n  padding: 0;\n  height: 500%;\n  overflow-y: scroll;\n}\n\n.custom > .dropdown > .dropdown-toggle {\n  display: inline-flex;\n  border-bottom: 1px solid lightgray;\n  height: 70px;\n}\n\n.custom.dropdown-item {\n  display: inline-flex;\n  border-bottom: 1px solid lightgray;\n  height: 70px;\n}\n\n.choose-quantity {\n  margin: auto 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
