// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hs-footer {
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-flow: column wrap;
  gap: 0;
  justify-content: flex-start;
  margin: 30px auto;
  padding: 0;
  position: relative;
  width: 100%;
}
.hs-footer > p {
  color: #212529;
  font-family: "Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  margin: 0 0 15px;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.scss"],"names":[],"mappings":"AAWA;EACE,yBAAA;EACA,uBAAA;EACA,aAAA;EACA,sBAAA;EACA,MAAA;EACA,2BAAA;EACA,iBAAA;EACA,UAAA;EACA,kBAAA;EACA,WAAA;AAVF;AAYE;EACE,cAAA;EACA,6MACE;EAaF,eAAA;EACA,gBAAA;EACA,kBAAA;AAvBJ","sourcesContent":["// Lab Footer CSS\n// ----------------------------------------\n\n// Imports\n// -------------------------\n\n@import '../../variables';\n\n// Footer\n// -------------------------\n\n.hs-footer {\n  align-content: flex-start;\n  align-items: flex-start;\n  display: flex;\n  flex-flow: column wrap;\n  gap: 0;\n  justify-content: flex-start;\n  margin: 30px auto;\n  padding: 0;\n  position: relative;\n  width: 100%;\n\n  > p {\n    color: $hs-shade-900;\n    font-family:\n      'Raleway',\n      -apple-system,\n      BlinkMacSystemFont,\n      'Segoe UI',\n      Roboto,\n      'Helvetica Neue',\n      Arial,\n      'Noto Sans',\n      sans-serif,\n      'Apple Color Emoji',\n      'Segoe UI Emoji',\n      'Segoe UI Symbol',\n      'Noto Color Emoji';\n    font-size: 1rem; // 16px\n    margin: 0 0 15px;\n    position: relative;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
